import style from './home03.css';
import logo_insta from '../media/img/logo_insta.png'


function Home03 () {
    return (
        <div className='home03' >
            <div className='home03_mobile'>
                <div className='home03_vertical01_mobile'>
                    <h1>QUANDO</h1>
                    <h2>Entre os dias<br/>
                        04, 05 e 06 de Outubro de 2024</h2>
                    <h1>ONDE</h1>
                    <h2>Ilha Tech<br/>
                        Rua Sandoval de Oliveira,<br /> 22 Torre</h2>
                    <h1>ACOMPANHE NOSSO INSTAGRAM</h1>
                    <div className='insta_mobile'>
                                <img src={logo_insta} alt="Logotipo do instagram" />
                                <a className='insta_link_mobile' href="https://www.instagram.com/nasaspacejampa/" target='blank'>@nasaspacejampa</a>
                    </div>
                </div>
                <div className='home03_vertical02_mobile'>
                    <h1>Abertura às 18h <br /> do dia 04/10</h1>
                    <h1>Encerramento às 18h <br /> do dia 06/10</h1>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3959.273529027208!2d-34.85000409640873!3d-7.0942601381767725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7acdd0e422da873%3A0x252e780cac7ff41c!2sCentro%20Universit%C3%A1rio%20-%20UNIESP!5e0!3m2!1spt-BR!2sbr!4v1688986219452!5m2!1spt-BR!2sbr" width="600" height="450" style={style} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h4>Reserve todo o final de semana<br/>
                        para ser maratonista. Vamos liberar<br /> a manhã do  Domingo para as eleições</h4>
                    <h4>Se tiver dúvidas, pergunte e<br/>
                        nos acompanhe nas redes sociais.</h4>
                </div>
            </div>

            <div className='home03_conteudo_full'>
                <div className='v01'>
                    <h1>QUANDO</h1>
                    <h2>Entre os dias<br/>
                        04, 05 e 06 de Outubro de 2024</h2>
                    <h1>ONDE</h1>
                    <h2>Ilha Tech<br/>
                        Rua Sandoval de Oliveira, 22 Torre{/*<br/>
                        Bloco E, 4º Andar */}</h2>
                        <h1>ACOMPANHE NOSSO INSTAGRAM</h1>
                    <div className='insta'>
                            <img src={logo_insta} alt="Logotipo do instagram" />
                            <a href="https://www.instagram.com/nasaspacejampa/" target='blank'>@nasaspacejampa</a>
                    </div>
                </div>
                
                <div className='v02'>
                    <h1>Abertura às 18h do dia 04/10</h1>
                    <h1>Encerramento às 20h do dia 06/10</h1>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.0084993104397!2d-34.86953109999999!3d-7.125010800000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ace8071ef7e34d%3A0x398d350b0cfaed03!2sR.%20Sandoval%20de%20Oliveira%2C%2022%20-%20Torre%2C%20Jo%C3%A3o%20Pessoa%20-%20PB%2C%2058040-110!5e0!3m2!1spt-BR!2sbr!4v1723908271442!5m2!1spt-BR!2sbr" width="600" height="450" style={style} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h4>Reserve todo o final de semana<br/>
                        para ser maratonista. Vamos liberar<br /> a manhã do  Domingo para as eleições</h4>
                    <h4>Se tiver dúvidas, pergunte e<br/>
                        nos acompanhe nas redes sociais.</h4>
                </div>
            </div>
        </div>
    );
};

export default Home03