import './globals.css'

const Globals = ({registrations, locations, countries, partners}) => {
    return (
        <div className="globals">
            <div className='globals_mobile'>
                <div>
                    <h1 className='number_mobile'>57K+</h1>
                    <h1 className='descri_mobile'>INSCRIÇÕES</h1>
                </div>
                <div>
                    <h1 className='number_mobile'>400+</h1>
                    <h1 className='descri_mobile'>LOCAIS</h1>
                </div>
                <div>
                    <h1 className='number_mobile'>152+</h1>
                    <h1 className='descri_mobile'>PAÍSES</h1>
                </div>
                <div>
                    <h1 className='number_mobile'>15</h1>
                    <h1 className='descri_mobile'>AGÊNCIAS ESPACIAIS PARCEIRAS</h1>
                </div>
                <h3 className='globals_link_mobile'>
                 As estatísticas são cumulativas ao longo de toda a história do evento Space Apps. <a href="https://www.spaceappschallenge.org/about/infographics/" target="blank">Clique aqui</a> para ver os resultados e métricas específicos do evento.
                </h3>
            </div>

            <div className='globals_conteudo_full'>
                <div>
                    <h1 className='number'>57K+</h1>
                    <h2 className='descri'>INSCRIÇÕES</h2>
                </div>
                <div>
                    <h1 className='number'>400+</h1>
                    <h2 className='descri'>LOCAIS</h2>
                </div>
                <div>
                    <h1 className='number'>152+</h1>
                    <h2 className='descri'>PAÍSES</h2>
                </div>
                <div>
                    <h1 className='number'>15</h1>
                    <h2 className='descri'>AGÊNCIAS ESPACIAIS PARCEIRAS</h2>
                </div>
                <h3>As estatísticas são cumulativas ao longo de toda a história do evento Space Apps. <a href="https://www.spaceappschallenge.org/about/infographics/" target="blank">Clique aqui</a> para ver os resultados e métricas específicos do evento.</h3>
            </div>
        </div>
    )
}

export default Globals;