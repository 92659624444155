import logo from './logo.svg';
import './App.css';

import Home from   './routes/home/home.jsx';
import Sobre from  './routes/sobre/sobre.jsx'
import Home01 from './routes/home/home01.jsx';
import Home02 from './routes/home/home02.jsx';
import Home03 from './routes/home/home03.jsx';
import Desafios from './routes/desafios/desafios.jsx';
import Footer from './routes/footer/footer.jsx';
import textBlockLeft from './components/textBlockLeft.jsx'
import Patrocinadores from './routes/patrocinadores/patrocinadores.jsx'


//Importando Router
import { BrowserRouter as Router, createBrowserRouter, RouterProvider, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="sobre" element={<Sobre />} />
          <Route path="desafios" element={<Desafios />} />
          <Route path="patrocinadores" element={<Patrocinadores />} />
          <Route path="textBlockLeft" element={<textBlockLeft />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
