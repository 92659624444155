import './footer.css'

import logo_insta from '../media/img/logo_insta.png'
import logo_linkedin from '../media/img/logo_linkedin.png'

function footer () {
        return (
            <div className='footer'>
                <h1>From Paraiba,<br/>
                    with Love</h1>
                <h1>N.A.S.A Space Apps<br/>
                    João Pessoa</h1>
                <a href="#"><img className='img' src={logo_insta} alt="" /></a>
                <a href="#"><img className='img' src={logo_linkedin} alt="" /></a>
            </div>
        )
}

export default footer