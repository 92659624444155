import './home.css';
import Home01 from './home01.jsx';
import Home02 from './home02.jsx';
import Home03 from './home03.jsx';
import Globals from '../../components/globals.jsx'
import Parceiros from '../../components/parceiros.jsx'
import Countdown from '../../components/countdown.jsx'
import Footer from '../footer/footer.jsx';
import '../../App.css';

import LogoNasa from '../media/img/logo_spaceapps.png'

import { Link } from 'react-router-dom';

import { BrowserRouter as Router } from "react-router-dom";

import { useState } from 'react';


function scrollDown() {
  const scrollHeight = document.documentElement.scrollHeight;
  const viewportHeight = window.innerHeight;
  const scrollDistance = (scrollHeight - viewportHeight) * 1.0;

  window.scrollTo({
    top: scrollDistance,
    behavior: 'smooth',
  });
}
function Patrocinadores() {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className='patrocinadores'>
      <div className='patrocinadores_cont'>
        <nav className='navbar'>
          <ul>
            <li id='quero'><a className='navtexto' href="#">Inscrições encerradas</a></li>
            <a className='logo_topo' href={"/"}><img src={LogoNasa} alt="" /></a>
            <Link className='link' to="/sobre">Sobre</Link>
            <Link className='link' to="/desafios">Desafios</Link>
            <li><Link className='link' to="/patrocinadores">Patrocinadores</Link></li>
            <li><a className='navtexto' onClick={scrollDown} href="#">Data/Local</a></li>
            <li><a className='navtexto' onClick={scrollDown} href="#">Contato</a></li>
          </ul>
        </nav>
        <h1>Patrocinadores</h1>

        <h2 id='realizacao'>Realização</h2>
        <h1></h1>

       
        <div className='botao_patrocinadores'>
          <a href="https://wa.me/5581979032074?text=Ol%C3%A1%2C+tudo+bem%3F+Eu+gostaria+de+falar+sobre+patroc%C3%ADnios+e+parcerias+com+o+N.A.S.A+Space+Apps+Jo%C3%A3o+Pessoa" target='blank' >
            <button className='button_apoiador' id='btn_patrocinador'>Quero ser um patrocinador!</button>
          </a>
        </div>
      </div>
    </div>
  );
}
function Home() {

  return (
    <div className="App">
      <Home01 />
      <Globals />    
      {/*<Home02 />*/}
      <Parceiros />
      <Home03 />
      <Footer />
    </div>
  );
}

export default Home;