import logo_nasa from '../media/img/logo_spaceapps.png'
import './desafios.css'
import Footer from '../footer/footer.jsx';
import '../footer/footer.css';

import React, { useRef, useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';

import Sobre from '../sobre/sobre.jsx'
import paginaSobre from '../sobre/sobre.jsx'

function scrollDown() { //Rolar automaticamente para algum ponto (porcentagem)
    const scrollHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
    const scrollDistance = (scrollHeight - viewportHeight) * 1.0; // Rolar 100% para baixo
  
    window.scrollTo({
      top: scrollDistance,
      behavior: 'smooth',
    });
  }
  

function Desafios () {
    return (
        <div className='desafios'>
            {/* Responsividade mobile*/}
            <div className='mobile'>
                <div className='topo_mobile'>
                    <a className='logo_topo_mobile' href={"/"}><img src={logo_nasa} alt="" /></a>
                    <div className='navbar_mobile'> {/* navbar versão mobile */}
                        <a className='link_top_mobile' onClick={scrollDown} href="#">Data/Local</a>
                        <a className='link_top_mobile' onClick={scrollDown} href="#">Contato</a>
                        <Link className='link_sobre_mobile' to="/sobre">Sobre</Link>
                        <Link className='link_sobre_mobile' to="/desafios">Desafios</Link>
                        <a className='quero_link_mobile' href="#">Inscrições encerradas</a>
                    </div>
                </div>
                <div className='info_mobile'>
                    <h1>A maior maratona global e oficial<br/>
                        da N.A.S.A</h1>
                    <div className='primeira_mobile'>
                        <h2>1ª</h2>
                        <h3>Edição em<br/>
                            João Pessoa</h3>
                    </div>
                </div>
                <div className='pilares_mobile'>
                    <h1>INOVAÇÃO</h1>
                    <h1>APRENDIZADO</h1>
                    <h1>CONEXÕES</h1>
                </div>
            </div>

            {/* Responsividade padrão/full*/}
            <div className='conteudo'>
                <nav className='navbar'>
                    <ul>
                        <li id='quero'><a className='navtexto' href="#">Inscrições encerradas</a></li>
                        <a className='logo_topo' href={"/"}><img src={logo_nasa} alt="" /></a>
                            <Link className='link' to="/sobre">Sobre</Link>
                            <Link className='link' to="/desafios">Desafios</Link>
                            <Link className='link' to="/sobre">Data/Local</Link>
                            <Link className='link' to="/sobre">Contato</Link>
                    </ul>
                </nav>
            </div>

            <div className='list_desafios_full'>
                <h1>DESAFIOS</h1>
                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Além da luz solar: um mundo aquático quimiossintético</h2>
                        <p>Os ecossistemas da Terra dependem muito da luz solar, mas imagine um mundo oceânico onde a quimiossíntese, não a fotossíntese, sustenta a vida. Seu desafio é projetar tal mundo e seu ecossistema diverso, expandindo os limites da astrobiologia além das condições tradicionais semelhantes às da Terra.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes, Astrofísica, Clima, Diversidade e Equidade, Exploração Espacial</h3>
                        <a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/beyond-sunlight-an-aquatic-chemosynthetic-world/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Crônicas da exploração de exoplanetas</h2>
                        <p>Embarque em uma jornada emocionante para revolucionar a educação sobre exoplanetas! A descoberta de exoplanetas redefiniu nossa compreensão dos sistemas planetários, expandindo o que sabemos sobre nosso lugar no universo. De gigantes gasosos escaldantes a mundos rochosos potencialmente habitáveis, esses mundos distantes oferecem um vislumbre da notável diversidade de configurações planetárias. Materiais educacionais tradicionais sobre este tópico podem não ser acessíveis a todos, especialmente aqueles de comunidades carentes ou com acesso limitado a recursos. Seu desafio é desenvolver materiais de aprendizagem envolventes e acessíveis que alavanquem a criatividade para esclarecer os alunos sobre as maravilhas dos exoplanetas.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes
Astrofísica,
Diversidade e Equidade,
Jogos,
Planetas e Luas,
Software,
Exploração Espacial</h3>
                        <a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/chronicles-of-exoplanet-exploration/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Mapeamento da Comunidade</h2>
                        <p>Um sistema de informações geográficas (GIS) pode criar, gerenciar, analisar e mapear muitos tipos de dados. Com o GIS e outras tecnologias de mapeamento, você pode criar um mapa de uma área e sobrepor dados abertos sobre ele espacialmente para revelar novos insights enriquecedores. Seu desafio é criar um mapa que incorpore dados científicos abertos para explorar como um problema em sua comunidade é moldado pela geografia física ao redor. Talvez você esteja preocupado com desertos alimentares e queira analisar a localização de supermercados em sua vizinhança? Ou talvez você queira explorar os impactos da poluição no abastecimento de água local? Há tanto espaço para oportunidades — tudo o que você precisa fazer é mapeá-las!</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes,
Clima,
Diversidade e Equidade,
Terra</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/community-mapping/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Crie um aplicativo da Web Orrery que exiba objetos próximos à Terra</h2>
                        <p>Desde que um modelo mecânico do sistema solar foi apresentado a Charles Boyle, 4º Conde de Orrery, em 1713, tais modelos têm sido chamados de orreries. Os primeiros orreries eram modelos físicos, mas hoje podemos usar inúmeras ferramentas para criar orreries virtuais que têm muito mais recursos do que suas antigas contrapartes mecânicas. Seu desafio é criar um aplicativo web de orrery interativo que seja incorporado em uma página da web e exiba corpos celestes como planetas, asteroides próximos da Terra, cometas próximos da Terra e asteroides potencialmente perigosos.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Astrofísica,
Jogos,
Planetas e Luas,
Software,
Exploração Espacial</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/create-an-orrery-web-app-that-displays-near-earth-objects/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Exosky!</h2>
                        <p>Como seria o céu noturno se você estivesse em um dos muitos exoplanetas descobertos por astrônomos e missões espaciais? A lista de mais de 5.500 exoplanetas no Arquivo de Exoplanetas da NASA pode ser combinada com os catálogos de estrelas mais recentes para traduzir a localização e o brilho de milhões ou até bilhões de estrelas para outra perspectiva. Dessa perspectiva, qualquer um poderia usar sua imaginação para desenhar constelações, assim como nossos ancestrais fizeram na Terra há milhares de anos. Seu desafio é desenvolver um aplicativo ou interface para alunos que permita que eles escolham um exoplaneta e então exibam um mapa estelar interativo ou exportem uma imagem de alta qualidade para impressão ou visualização em um computador ou tela de realidade virtual, onde eles podem desenhar e nomear constelações.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Astrofísica,
Planetas e Luas,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/exosky/">Acessar desafio</a>
                    </div>
                    

                    <div className='desafio'>
                        <h2>Galactic Games: diversão em um ambiente de microgravidade!</h2>
                        <p>Imagine um cenário onde a colonização espacial atingiu o ponto em que várias estações espaciais orbitam vários corpos celestes dentro do sistema solar. Os astronautas devem viver, trabalhar e brincar por longos períodos em ambientes de microgravidade. Viver nessas condições não só afeta seu bem-estar físico, como às vezes eles ficam entediados! Sua tarefa é criar um jogo que aproveite os desafios e oportunidades únicos de um ambiente de microgravidade para entreter e contribuir para o bem-estar geral e a coesão das equipes de astronautas, aumentando sua resiliência e adaptabilidade na fronteira extraterrestre.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Astrofísica,
Planetas e Luas,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/galactic-games-fun-in-a-microgravity-environment/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Género e Clima</h2>
                        <p>Os Objetivos de Desenvolvimento Sustentável das Nações Unidas são uma série de metas projetadas para promover o desenvolvimento humano e proteger o meio ambiente. Dois dos objetivos dizem respeito à igualdade de gênero e à ação climática. Embora a maioria das pessoas possa considerar esses dois tópicos bastante díspares, eles estão, na verdade, intimamente relacionados. Seu desafio é modelar a relação entre mudança climática e desigualdade de gênero e propor uma solução que promova igualdade e ação para apoiar o desenvolvimento sustentável para todos.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Clima,
Diversidade e Equidade,
Terra</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/gender-and-climate/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Jogos do Protocolo GLOBE</h2>
                        <p>Aprender sobre ciência pode ser uma experiência divertida! Jogos que focam em ciência e questões ambientais podem criar oportunidades para os jogadores desenvolverem seus interesses e ganharem conhecimento enquanto se envolvem uns com os outros de uma forma divertida. Seu desafio é criar um jogo que integre os protocolos do Programa Global Learning and Observations to Benefit the Environment (GLOBE) para ajudar os jogadores a entender o mundo ao seu redor, desenvolver a conscientização sobre um ou mais tópicos ambientais locais ou globais (por exemplo, urbanização, poluição da água, seca, ondas de calor, inundações), investigar sua comunidade local usando os protocolos GLOBE ou aprender princípios científicos.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes,
Clima,
Diversidade e Equidade,
Terra,
Jogos,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/globe-protocol-games/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Imagine nossa Terra conectada</h2>
                        <p>Nossos sistemas terrestres estão intrinsecamente conectados em uma escala global. Por exemplo, as mudanças climáticas podem aumentar a duração e a gravidade das secas, o que pode tornar os incêndios florestais mais prevalentes, o que pode não apenas levar à má qualidade do ar devido à fumaça, mas também pode influenciar a sucessão ecológica e a regeneração florestal. O Earth Information Center trabalha para comunicar exemplos dessas interconexões ao público por meio de exibições físicas e uma plataforma online, mas novas ideias sobre como comunicar essa importante mensagem ao público são sempre bem-vindas! Seu desafio é criar um produto interativo audiovisual (por exemplo, uma animação, vídeo, experiência imersiva baseada na web, aplicativo musical, etc.) que incorpore imagens e dados da frota de satélites de observação da Terra da NASA para destacar e explorar as interconexões entre os sistemas da Terra e demonstrar como as mudanças em um sistema podem alterar outro sistema.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes,
Clima,
Terra,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/imagine-our-connected-earth/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Dados de refletância do Landsat: em tempo real e na ponta dos seus dedos</h2>
                        <p>As missões Landsat forneceram o maior conjunto de dados contínuos de medições de sensoriamento remoto da superfície terrestre da Terra. Comparar medições espectrais terrestres com dados de Refletância de Superfície (SR) do Landsat coletados ao mesmo tempo pode facilitar o aprendizado experimental, encorajar a exploração científica com dados de satélite, promover habilidades de pensamento interdisciplinar e espacial e capacitar indivíduos a se tornarem cidadãos globais informados. Mas para comparar medições terrestres com dados Landsat, você precisa saber quando o Landsat passará por uma área terrestre específica e, então, ser capaz de acessar os dados Landsat coletados naquele local e hora. Essa tarefa especializada e trabalhosa ainda precisa ser integrada em um único aplicativo coeso. Seu desafio é desenvolver um aplicativo baseado na web que suporte a comparação de observações terrestres com dados Landsat, permitindo que os usuários definam um local de destino, recebam notificações quando o Landsat passar por esse local e, então, acessem e exibam os dados SR do Landsat correspondentes.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Earth,
                        Software</h3>
                        <a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/landsat-reflectance-data-on-the-fly-and-at-your-fingertips/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Aproveitando dados de observação da Terra para tomada de decisões agrícolas informadas</h2>
                        <p>Os agricultores enfrentam uma enxurrada de desafios relacionados à água devido ao clima imprevisível, pragas e doenças. Esses fatores podem impactar significativamente a saúde das colheitas, os lucros dos agricultores e a segurança alimentar. Dependendo da geografia, muitos agricultores podem enfrentar secas ou inundações — às vezes, esses dois eventos extremos ocorrem na mesma estação! Seu desafio é projetar uma ferramenta que capacite os agricultores a explorar, analisar e utilizar facilmente os conjuntos de dados da NASA para abordar essas preocupações relacionadas à água e melhorar suas práticas agrícolas.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Clima,
Diversidade e Equidade,
Terra,
Hardware,
Software</h3>   
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/leveraging-earth-observation-data-for-informed-agricultural-decision-making/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Navegador para o Observatório de Mundos Habitáveis ​​(HWO): Mapeando os Exoplanetas Caracterizáveis ​​em nossa Galáxia</h2>
                        <p>Quais exoplanetas serão observáveis ​​com o futuro Habitable Worlds Observatory (HWO)? Uma maneira de determinar os alvos exoplanetários mais interessantes para o HWO seria visualizar quais dos exoplanetas atualmente conhecidos o HWO poderia potencialmente observar. Seu desafio é desenvolver um aplicativo que permita aos usuários visualizar os caminhos observacionais através de nossa galáxia para os exoplanetas conhecidos na vizinhança solar como uma função de seu potencial para caracterização pelo HWO.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Astrofísica,
Planetas e Luas,
Software,
Exploração Espacial</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/navigator-for-the-habitable-worlds-observatory-hwo-mapping-the-characterizable-exoplanets-in-our-galaxy/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>PACE na sala de aula</h2>
                        <p>O satélite Plankton Aerosol Cloud ocean Ecosystem (PACE) foi lançado e começou a retornar dados sobre os oceanos e a atmosfera da Terra. A política de ciência aberta da NASA permite que todos os dados do PACE sejam acessados ​​pelo público, mas pode ser difícil de entender se você ainda não estiver familiarizado com esses tipos de dados. Seu desafio é criar um conjunto digerível de materiais que possam ser usados ​​em salas de aula em todo o mundo para ajudar os alunos a entender os dados e informações que o PACE está coletando e melhorar a alfabetização oceânica em todo o mundo.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Artes,
Clima,
Terra,
Jogos</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/pace-in-the-classroom/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>ODS na sala de aula</h2>
                        <p>Em 2015, as Nações Unidas criaram a Agenda dos Objetivos de Desenvolvimento Sustentável (ODS) 2030 — um conjunto de 17 objetivos focados em abordar preocupações globais como mudanças climáticas, pobreza global, poluição, desigualdade e muito mais. À medida que embarcamos no ponto médio da Agenda dos ODS, pretendemos encontrar novas maneiras de envolver os jovens com as ferramentas disponíveis para avançar esses objetivos e medir nosso progresso em alcançá-los. Seu desafio é desenvolver um plano de aula que eduque os alunos do ensino médio sobre um ODS e que possa ser integrado a uma unidade de ciências que já faça parte do currículo (por exemplo, uma unidade sobre clima, geologia, saúde do solo, etc.).</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Arts,
Climate,
Diversity & Equity,
Earth,
Games,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/sdgs-in-the-classroom/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Detecção sísmica em todo o sistema solar</h2>
                        <p>As missões de sismologia planetária lutam com os requisitos de energia necessários para enviar dados sísmicos contínuos de volta à Terra. Mas apenas uma fração desses dados é cientificamente útil! Em vez de enviar de volta todos os dados coletados, e se pudéssemos programar um módulo de pouso para distinguir sinais de ruído e enviar de volta apenas os dados que nos interessam? Seu desafio é escrever um programa de computador para analisar dados reais das missões Apollo e do Mars InSight Lander para identificar terremotos sísmicos dentro do ruído!</p>
                        <h2>Dificuldade</h2>
                        <h3>Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Planetas e Luas</h3>
                        <a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/seismic-detection-across-the-solar-system/">Acessar desafio</a>     
                    </div>

                    <div className='desafio'>
                        <h2>Mostre-nos como foram as tempestades geomagnéticas de maio de 2024</h2>
                        <p>O Sol é altamente variável e passa por períodos em que sua atividade magnética é muito alta e momentos em que há muito pouca ou quase nenhuma atividade magnética. Este ano, o Sol estava muito ativo! Em maio de 2024, várias grandes erupções solares criaram um clima espacial que afetou a Terra de várias maneiras. Seu desafio é usar dados da NASA para criar uma representação visual (por exemplo, um filme, vídeo de uma dança, etc.) que ajude o público a entender melhor as tempestades solares de maio de 2024 e seus impactos.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Arts,
Diversity & Equity,
Earth,
Planets & Moons,
Sun</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/show-us-what-the-may-2024-geomagnetic-storms-looked-like/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Sinfonia das Estrelas: Harmonizando o Telescópio Espacial James Webb em Música e Imagens</h2>
                        <p>O Telescópio Espacial James Webb (JWST), com suas capacidades sem precedentes, foi projetado para explorar alguns dos tópicos mais profundos da astronomia — do nascimento de estrelas e galáxias à busca por sinais de vida além do nosso sistema solar. Suas descobertas provavelmente remodelarão nossa compreensão do universo, despertando curiosidade e admiração. Seu desafio é capturar o espírito desta missão notável criando uma colagem que mescle visuais JWST impressionantes com um cenário musical envolvente, encapsulando a sensação de descoberta e admiração cósmica que o telescópio inspira de uma forma que atraia pessoas de todas as idades.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante (jovens) | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Arts,
Astrophysics,
Diversity & Equity,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/symphony-of-the-stars-harmonizing-the-james-webb-space-telescope-in-music-and-images/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Conte-nos uma história sobre o clima!</h2>
                        <p>Nas últimas décadas, uma enorme quantidade de dados climáticos de várias fontes foi coletada. Esses dados estão disponíveis gratuitamente ao público, mas entender essa vasta quantidade de dados não é fácil! Seu desafio é usar os dados de código aberto no site do U.S. Greenhouse Gas Center para contar uma história convincente sobre as mudanças climáticas.</p>
                        <h2>Dificuldade</h2>
                        <h3>Iniciante | Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Arts,
Climate,
Diversity & Equity,
Earth,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/tell-us-a-climate-story/">Acessar desafio</a>
                    </div>
                </div>

                <div className='linha_divisoria'></div>

                <div className='bloco_desafio'>
                    <div className='desafio'>
                        <h2>Descubra o papel dos gases de efeito estufa em sua vizinhança!</h2>
                        <p>As emissões de gases de efeito estufa causadas pelo homem (antropogênicas) e os sistemas naturais que produzem e absorvem gases de efeito estufa (fontes e sumidouros) interagem de maneira complicada para contribuir para a mudança climática global. À medida que os formuladores de políticas e os cidadãos correm para combater a mudança climática, entender essa interação é mais importante do que nunca. Seu desafio é usar uma combinação de conjuntos de dados de satélite e baseados em modelos para mapear as emissões de gases de efeito estufa causadas pelo homem e naturais para permitir uma melhor compreensão de como essas emissões contribuem para um mundo mais quente.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Clima,
Diversidade e Equidade,
Terra,
Software</h3>
<a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/uncover-the-role-of-greenhouse-gases-in-your-neighborhood/">Acessar desafio</a>
                    </div>

                    <div className='desafio'>
                        <h2>Visualize a Ciência Espacial</h2>
                        <p>Experimentos biológicos realizados no espaço são essenciais para os esforços de descoberta científica, mas são complexos de executar e difíceis de conceituar. Por exemplo, esses experimentos devem ser carregados em foguetes, lançados, realizados usando hardware especializado e (frequentemente) devolvidos à Terra para análise. Essa complexidade é uma barreira para a ampla utilização desses dados. Seu desafio é criar uma ferramenta que possa gerar visualizações informativas e atraentes de experimentos biológicos realizados no espaço.</p>
                        <h2>Dificuldade</h2>
                        <h3>Intermediário | Avançado</h3>
                        <h2>Assuntos</h2>
                        <h3>Software,
                        Exploração Espacial</h3>
                        <a href="https://www.spaceappschallenge.org/nasa-space-apps-2024/challenges/visualize-space-science/">Acessar desafio</a>
                    </div>
                </div>                
            </div>



        </div>   
    )
}

export default Desafios