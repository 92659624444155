import './patrocinadores.css';
import React from 'react';
import LogoTresbe from '../media/img/logo_tresbe.png';
import LogoIlha from '../media/img/logo_ilha.png';
import JM from '../media/img/3JM Gráfica.png';
import tvpb from '../media/img/logo tv PB branca.png';
import cabobranco from '../media/img/logo tv cabo branco branca.png';
import JB from '../media/img/LOGO JB.png';
import Geane from '../media/img/Geane.png';
import Paraibana from '../media/img/ParaibanaTech.png';
import Manaira from '../media/img/Logo_Manaira.png';
import Fablab from '../media/img/Logo FabLab-UFPB.png';
import logo_nasa from '../media/img/logo_spaceapps.png'

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';

function Patrocinadores() {
  return (
    <div id='patrocinadores'>

            {/* Responsividade mobile*/}
            <div className='mobile'>
                <div className='topo_mobile'>
                    <a className='logo_topo_mobile' href={"/"}><img src={logo_nasa} alt="" /></a>
                    <div className='navbar_mobile'> {/* navbar versão mobile */}
                        <Link className='link_sobre_mobile' to="/sobre">Sobre</Link>
                        <Link className='link_sobre_mobile' to="/desafios">Desafios</Link>
                        <a className='quero_link_mobile' href="#">Inscrições encerradas</a>
                    </div>
                </div> </div>

    <nav className='navbar'>
                    <ul>
                        <li id='quero'><a className='navtexto' href="#">Inscrições encerradas</a></li>
                        <a className='logo_topo' href={"/"}><img src={logo_nasa} alt="" /></a>
                            <Link className='link' to="/sobre">Sobre</Link>
                            <Link className='link' to="/desafios">Desafios</Link>
                             <li><Link className='link' to="/patrocinadores">Patrocinadores</Link></li>
                    </ul>
      </nav>

      <h1 className='titulo_patrocinadores'>Realização</h1>
      <a className='logo_patrocinadores_page' href="https://www.tresbedelas.com.br/">
        <img src={LogoTresbe} alt="Logo Tresbe" />
      </a>
      <a className='logo_patrocinadores_page' href="https://www.instagram.com/ilhatechoficial/">
        <img src={LogoIlha} alt="Logo Ilha" />
      </a>
      
      <h1 className='titulo_patrocinadores'>Apoio Institucional</h1>
      <a className='logo_patrocinadores_page' href="https://www.instagram.com/tvparaiba/">
        <img src={tvpb} alt="TV Paraíba" />
      </a>
      <a className='logo_patrocinadores_page' href="https://www.instagram.com/tvcabobranco/">
        <img src={cabobranco} alt="TV Cabo Branco" />
      </a>

      <h1 id='titulo_patrocinadores'>Patrocinadores</h1>
      <h2>Estrela</h2>
      <a className='logo_patrocinadores_page' href="https://www.instagram.com/grafica.3jm/">
        <img src={JM} alt="Gráfica 3JM" />
      </a>
      <a className='logo_patrocinadores_page' href="https://geaneamaral.com.br/">
        <img src={Geane} alt="Geane Amaral" />
      </a>
      <a className='logo_patrocinadores_page' href="#">
        <img src={Paraibana} alt="Paraibana Tech" />
      </a>
      
      <h2>Lua</h2>
      <a className='logo_patrocinadores_page' href="https://www.instagram.com/graficajb/">
        <img src={JB} alt="Gráfica JB" />
      </a>

      <h2>Marte</h2>
      <a className='logo_patrocinadores_page' href="https://manairashopping.com/">
        <img src={Manaira} alt="Manaíra Shopping" />
      </a>

      <h2>Terra</h2>
      <a className='logo_patrocinadores_page' href="https://www.ufpb.br/fablab/">
        <img src={Fablab} alt="FabLab UFPB" />
      </a>
    </div>
  );
}

export default Patrocinadores;
