import logo_nasa from '../media/img/logo_spaceapps.png'
import './home01.css'

import React, { useRef, useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';

import Sobre from '../sobre/sobre.jsx'
import paginaSobre from '../sobre/sobre.jsx'

function scrollDown() { //Rolar automaticamente para algum ponto (porcentagem)
    const scrollHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
    const scrollDistance = (scrollHeight - viewportHeight) * 0.5; // Rolar 100% para baixo
  
    window.scrollTo({
      top: scrollDistance,
      behavior: 'smooth',
    });
  }
  

function Home01 () {
    return (
        <div className='home01'>
            {/* Responsividade mobile*/}
            <div className='mobile'>
                <div className='topo_mobile'>
                    <a className='logo_topo_mobile' href={"/"}><img src={logo_nasa} alt="" /></a>
                    <div className='navbar_mobile'> {/* navbar versão mobile */}
                        <Link className='link_sobre_mobile' to="/sobre">Sobre</Link>
                        <Link className='link_sobre_mobile' to="/desafios">Desafios</Link>
                        <a className='quero_link_mobile' href="#">Inscrições encerradas</a>
                    </div>
                </div>
                <div className='info_mobile'>
                    <h1>A maior maratona global e oficial<br/>
                        da N.A.S.A</h1>
                    <div className='primeira_mobile'>
                        <h2>2ª</h2>
                        <h3>Edição em<br/>
                            João Pessoa</h3>
                    </div>
                </div>
                <div className='pilares_mobile'>
                    <h1>INOVAÇÃO</h1>
                    <h1>APRENDIZADO</h1>
                    <h1>CONEXÕES</h1>
                </div>
            </div>

            {/* Responsividade padrão/full*/}
            <div className='conteudo'>
                <nav className='navbar'>
                    <ul>
                        <li id='quero'><a className='navtexto' href="#">Inscrições encerradas</a></li>
                        <a className='logo_topo' href={"/"}><img src={logo_nasa} alt="" /></a>
                            <Link className='link' to="/sobre">Sobre</Link>
                            <Link className='link' to="/desafios">Desafios</Link>
                    </ul>
                </nav>
                <div className='definicao'>
                    <h1>A maior maratona global e oficial<br/>da N.A.S.A</h1>
                    <div className='primeiraedicao'>
                        <h2>2ª</h2>
                        <h3>Edição em<br/>
                            João Pessoa</h3>
                    </div>
                </div>
                <div className='pilares'>
                    <h2>INOVAÇÃO</h2><h2>APRENDIZADO</h2><h2>CONEXÃO</h2>
                </div>
            </div>
        </div>   
    )
}

export default Home01