import './sobre.css'
import style from '../home/home03.css';
import Footer from '../footer/footer.jsx'

import logo_nasa from '../media/img/logo_spaceapps.png';

import textBlockLeft from '../../components/textBlockLeft.jsx'

import fotoJoao from '../media/img/perfil_joaomoizes.png';
import fotoDani from '../media/img/perfil_danibezerra.png';
import fotoThay from '../media/img/perfil_thayanebelchior.png';
import fotoBeatriz from '../media/img/perfil_beatriz.png';
import fotoCamila from '../media/img/perfil_camilaribeiro.png';
import fotoCarol from '../media/img/perfil_carol.png';
import fotoEvellyn from '../media/img/perfil_evellyn.png';
import fotoFernanda from '../media/img/perfil_fernanda.png';
import fotoIsabelly from '../media/img/perfil_isabelly.png';
import fotoJuraci from '../media/img/perfil_juraci.png';
import fotoMarialuiza from '../media/img/perfil_marialuiza.png';
import fotoRaizafeitosa from '../media/img/perfil_raizafeitosa.png';
import fotoRaquel from '../media/img/perfil_raquel.png';
import fotoGabriel from '../media/img/perfil_gabriel.png';
import fotoLays from '../media/img/Lays Andrade.png';
import fotoLarissa from '../media/img/Larissa Sampaio.png';
import fotoKelly from '../media/img/Kelly Pereira.png';
import fotoLJulianna from '../media/img/Julianna Dutra.png';
import fotojuliana from '../media/img/Juliana Borges.png';
import fotoJosinaldo from '../media/img/Josinaldo.png';
import fotoJannifer from '../media/img/Janiffer Macena.png';
import fotoJaniele from '../media/img/Janiele Nery.png';
import fotoElysson from '../media/img/Elysson Marconi.png';
import fotoAlberlene from '../media/img/Alberlene Baracho.png';
import fotoFernandasergio from '../media/img/Fernanda Sergio.png';
import fotoGlaucy from '../media/img/Glaucy.png';
import fotoMarcelo from '../media/img/Marcelo Fernandes.png';
import fotoMarcelomoura from '../media/img/Marcelo Moura.png';
import fotoMichele from '../media/img/Michele Santos.png';
import fotoRonaldo from '../media/img/Ronaldo Farias.png';
import fotoSevy from '../media/img/Sevy Ramo.png';
import fotoSuellen from '../media/img/Suellen Finizola.png';
import fotoRuy from '../media/img/Ruy Lopes.png';
import fotoClaudia from '../media/img/Claudia Balbino.png';





import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';

function scrollDown() { //Rolar automaticamente para algum ponto (porcentagem)
    const scrollHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
    const scrollDistance = (scrollHeight - viewportHeight) * 1.0; // Rolar 100% para baixo
  
    window.scrollTo({
      top: scrollDistance,
      behavior: 'smooth',
    });
  }

function Sobre () {
    return (
        <div className='sobre'>

            <div className='sobre_mobile'>
                <div className='sobre_topo_mobile'>
                        <a className='sobre_logo_topo_mobile' href={"/"}><img src={logo_nasa} alt="" /></a>
                        <div className='sobre_navbar_mobile'> {/* navbar versão mobile */}
                            <a className='sobre_quero_link_mobile' href="#">Inscrições encerradas</a>
                        </div>
                </div>
            </div>

            <div className='sobre_conteudo_full'>
                
            <nav className='navbar'>
                    <ul>
                        <li id='quero'><a className='navtexto' href="#">Inscrições encerradas</a></li>
                        <a className='logo_topo' href={"/"}><img src={logo_nasa} alt="" /></a>
                        
                           {/*  <Link className='link' to="/desafios">Desafios</Link>*/}
                            <li><a className='navtexto' onClick={scrollDown} href="#">Data/Local</a></li>
                            <li><a className='navtexto' onClick={scrollDown} href="#">Patrocinadores</a></li>
                            <li><a className='navtexto' onClick={scrollDown} href="#">Contato</a></li>
                    </ul>
                </nav>

                <section className='textos'>
                    <div className='vertical01'>
                        <div className='blocoTexto'>
                            <h1>O que é o N.A.S.A Space Apps</h1>
                              <p>É um programa de Hackathons global, oficial, da N.A.S.A (National Aeronautics and Space
                                Administration of USA), que reúne maratonistas de todo o globo, em diferentes polos ao mesmo tempo. São 48h de imersão, em que você e seu time poderão escolher um dos desafios oficiais da N.A.S.A e solucioná-los.</p>
                        </div>
                        <div className='blocoTexto'>
                                <h1>Como posso me inscrever?</h1>
                                <p>Através do link no topo da tela você poderá preencher
                                    um formulário de inscrição.
                                    Suas respostas serão analisadas e em breve iremos
                                    anunciar a lista dos selecionados.
                                    Mas não se preocupe, o intuito do N.A.S.A Space Apps
                                    é reunir todos os tipos de maratonistas, inclusive
                                    pessoas que não são do setor de T.I.</p>
                        </div>
                        <div className='blocoTexto'>
                                        <h1>Como funciona na prática?</h1>
                                        <p>Por ser um Hackathon, o N.A.S.A Space Apps consiste
                                            em uma maratona de 48h em que os participantes irão
                                            formar seus grupos, escolher um desafio dentre os
                                            oficiais da N.A.S.A e desenvolver uma solução funcional
                                            (MVP) que será avaliada por uma banca de jurados.
                                            Os três primeiros colocados serão premiados com incríveis
                                            prêmios que serão anunciados durante o evento.</p>
                        </div>
                        <div className='blocoTexto'>
                                            <h1>Mas 48h não é muito pouco?</h1>
                                            <p>O intuito principal da maratona são os aprendizados,
                                            conexões, amizades, práticas e boas memórias geradas
                                            durante a imersão.
                                            Comumente, a maioria dos grupos sempre conseguem
                                            entregar suas soluções à tempo, mesmo que não tenham
                                            experiência com desenvolvimento, design ou negócios.
                                            <br />
                                            Então se inscreva, pegue sua garrafa e casaco.
                                            E nos vemos em breve!</p>
                        </div>
                        </div>

                        <div className='vertical02'>
                            <div className='blocoTexto'>
                            <h1>Este site é para qual polo?</h1>
                            <p>Este website é focado na edição de João Pessoa (PB).
                            Um dos polos presentes pelo Brasil. Para mais informações
                            visite o site oficial do N.A.S.A Space Apps
                            https://www.spaceappschallenge.org</p>
                            </div>
                            <div className='blocoTexto'>
                            <h1>Quanto custa para participar?</h1>
                            <p>Exatos R$0,00.
                            O N.A.S.A Space Apps é um evento gratuito e
                            voluntário. Que é viabilizado através da dedicação
                            da sua liderança, organização e mentores convidados e voluntários.
                            Além, do suporte dos nossos apoiadores oficiais.</p>
                        </div>
                        <div className='blocoTexto'>
                            <h1>Eu preciso já ter um time?</h1>
                            <p>Não. É possível tanto já se inscrever com um time formado,
                            ou também formar seu grupo no primeiro dia, como
                            acontece com a maioria dos maratonistas participantes.</p>
                        </div>
                        </div>  
 
                    </section>
                {/*
                    <div className='information' >
                    <div className='info01'>
                        <h1>QUANDO</h1>
                        <h2>Entre os dias<br/>
                            04, 05 e 06 de Outubro de 2024</h2>
                        <h1>ONDE</h1>
                        <h2>Ilha tech<br/>
                            Rua Sandoval de Oliveira, 22 Torre, João Pessoa/PB.<br/>
                            </h2>
                    </div>  
                    
                    <div className='info02'>
                        <h1>Abertura às 18h do dia 04/10</h1>
                        <h1>Encerramento às 18h do dia 06/10</h1>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8887.673003135526!2d-34.86939770708086!3d-7.125027865066315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ace8071ef7e34d%3A0x398d350b0cfaed03!2sR.%20Sandoval%20de%20Oliveira%2C%2022%20-%20Torre%2C%20Jo%C3%A3o%20Pessoa%20-%20PB%2C%2058040-110!5e0!3m2!1spt-BR!2sbr!4v1723908930712!5m2!1spt-BR!2sbr" width="600" height="450" style={style} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        <h4>Reserve todo o final de semana<br/>
                            para ser maratonista. Vamos liberar a <br /> manhã do Domingo para as eleições.</h4>
                        <h4>Se tiver dúvidas, pergunte e<br/>
                            nos acompanhe nas redes sociais.</h4>
                    </div>
                    </div>
                */}

                    <div className='sobre_time'>
                        <h1 id='titulo_sobre_time'>QUEM FAZ ACONTECER</h1>

                        <div className='bloco_sobre_time'>
                            <h2 className='titulo_bloco_time'>Liderança</h2>
                          <div className='perfis_time'>
                                <div className='perfil_time'>
                                    <img src={fotoDani} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Dani Bezerra</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/dani-bezerra/">LinkedIn</a>
                                        <a target='blank' href="https://www.instagram.com/borafaladeira/">Instagram</a>
                                    </div>
                                </div>   
                                <div className='perfil_time'>
                                    <img src={fotoThay} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Thayane Belchior</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/thayanebelchior/">LinkedIn</a>
                                        <a target='blank' href="https://www.instagram.com/tresbedelas/">Instagram</a>
                                    </div>
                                </div>
                                <div className='perfil_time'>
                                    <img src={fotoJoao} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>João Moizes</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/joao-moizes/">LinkedIn</a>
                                        <a target='blank' href="https://www.instagram.com/joao.moizes/">Instagram</a>
                                    </div>
                                </div>
                                <div className='perfil_time'>
                                    <img src={fotoRuy} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Ruy Lopes</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/ruylopesdebarros/">LinkedIn</a>
                                        <a target='blank' href="https://www.instagram.com/ruyolopes/">Instagram</a>
                                    </div>
                                </div>        
                            </div>
                        </div>

                        <div className='bloco_sobre_time'>
                            <h2 className='titulo_bloco_time'>Equipe</h2>
                            <div className='perfis_time'>
                                
                                
                                <div className='perfil_time'>
                                    <img src={fotoFernanda} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Fernanda Lima</h3>
                                        <a target='blank' href="https://www.LinkedIn.com/in/limasfernanda/">LinkedIn</a>
                                    </div>
                                </div>   
                                <div className='perfil_time'>
                                    <img src={fotoGabriel} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Gabriel Silva</h3>
                                        <a target='blank' href="https://www.LinkedIn.com/in/gabriel-silva-131a92228/">LinkedIn</a>
                                        <a target='blank' href="https://www.instagram.com/_gabriell.sillva_/">Instagram</a>
                                    </div>
                                </div>
                                <div className='perfil_time'>
                                    <img src={fotoBeatriz} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Beatriz Paredes</h3>
                                        <a target='blank' href="https://www.instagram.com/beatrizscaff/">Instagram</a>
                                    </div>
                                </div>  
                            
                                <div className='perfil_time'>
                                    <img src={fotoClaudia} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Claudia Balbino</h3>
                                        <a target='blank' href="https://www.instagram.com/claudinhabalbino/">Instagram</a>
                                        <a target='blank' href="www.linkedin.com/in/claudiabalbino">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoRaquel} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Raquel Silveira</h3>
                                        <a target='blank' href="https://www.instagram.com/silveiraquel/">Instagram</a>
                                        <a target='blank' href="https://www.linkedin.com/in/raquel-silveira-1863b9208/">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoCarol} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Carol de Lourdes</h3>
                                        <a target='blank' href="https://www.LinkedIn.com/in/carolina-r%C3%AAgo-a07552144/">LinkedIn</a>
                                        <a target='blank' href="https://instagram.com/caroldelourdes">Instagram</a>
                                    </div>
                                </div>

                            </div>
                        </div>
{/*
                        <div className='bloco_sobre_time'>
                            <h2 className='titulo_bloco_time'>Mentores</h2>
                       DUVIDA     <div className='perfis_time'>
                                <div className='perfil_time'>
                                    <img src={fotoSevy} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Sevy Ramo</h3>
                                        <a target='blank' href="https://linkedIn.com/in/sevyramo">LinkedIn</a>
                                    </div>
                                </div>   
                            LARISSA    <div className='perfil_time'>
                                    <img src={fotoLarissa} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Larissa Dias</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/larissa-sampaio-eng%C2%AA-civil-msc-inova%C3%A7%C3%A3o-a7320172">LinkedIn</a>
                                    </div>
                                </div>
                            DUVIDA    <div className='perfil_time'>
                                    <img src={fotoJaniele} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Fernanda Lima</h3>
                                        <a target='blank' href="www.linkedin.com/in/janielenery">LinkedIn</a>
                                    </div>
                                </div>   
                            DUVIDA    <div className='perfil_time'>
                                    <img src={fotoAlberlene} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Alberlene Barracho</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/alberlene-baracho?originalSubdomain=br">LinkedIn</a>
                                    </div>
                                </div>
                           DUVIDA     <div className='perfil_time'>
                                    <img src={fotoMichele} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Michele Santos</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/michele-santos-580642179/">LinkedIn</a>
                                    </div>
                                </div>
                           DUVIDA     <div className='perfil_time'>
                                    <img src={fotoJannifer} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Janiffer Macena</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/janiffer-macena/">LinkedIn</a>
                                    </div>
                                </div>
                                <div className='perfil_time'>
                                    <img src={fotoSuellen} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Suellen Finizola</h3>
                                    </div>
                                </div>  
                                <div className='perfil_time'>
                                    <img src={fotoGlaucy} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Glaucy Oliveira</h3>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoLays} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Lays Fernanda</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/lays-andrade-50a564139/">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotojuliana} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Juliana Borges</h3>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoLJulianna} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Juliana Dutra</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/julianna-dutra-b23bb21a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoFernanda} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Fernanda Sergio</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/fernanda-sergio-2b9a1a128">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoMarcelo} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Marcelo Fernandes</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/marcelo-fernandes-426663197/">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoElysson} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Elysson Marconi</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/elysson-marconi-529952210">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoRonaldo} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Ronaldo de Araújo</h3>
                                        <a target='blank' href="https://www.linkedin.com/company/labor-laborat%C3%B3rio-de-inova%C3%A7%C3%A3o-do-trt-pb/">LinkedIn</a>
                                    </div>
                                </div> 
                                <div className='perfil_time'>
                                    <img src={fotoJosinaldo} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Josinaldo de Pontes</h3>
                                        <a target='blank' href="https://www.linkedin.com/in/josinaldo-j%C3%BAnior/">LinkedIn</a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        
                        {/* 
                        <div className='bloco_sobre_time'>
                            <h2 className='titulo_bloco_time'>Mentores</h2>
                            <div className='perfis_time'>
                                <div className='perfil_time'>
                                    <img src={fotoDani} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Dani Bezerra</h3>
                                        <a href="#">LinkedIn</a>
                                        <a href="#">Instagram</a>
                                    </div>
                                </div>   
                                <div className='perfil_time'>
                                    <img src={fotoThay} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>Thayane Belchior</h3>
                                        <a href="#">LinkedIn</a>
                                        <a href="#">Instagram</a>
                                    </div>
                                </div>
                                <div className='perfil_time'>
                                    <img src={fotoJoao} alt="" />
                                    <div className='dados_perfil_time'>
                                        <h3>João Moizes</h3>
                                        <a href="#">LinkedIn</a>
                                        <a href="#">Instagram</a>
                                    </div>
                                </div>        
                            </div>
                        </div>
                        */}
                    </div>
                    <Footer />
            </div>

            
        </div>
    )
}

export default Sobre