import './parceiros.css';
import React from 'react';
import LogoTresbe from '../routes/media/img/logo_tresbe.png';
import LogoIlha from '../routes/media/img/logo_ilha.png';
import JM from '../routes/media/img/3JM Gráfica.png';
import tvpb from '../routes/media/img/logo tv PB branca.png';
import cabobranco from '../routes/media/img/logo tv cabo branco branca.png';
import JB from '../routes/media/img/LOGO JB.png';
import Geane from '../routes/media/img/Geane.png';
import Paraibana from '../routes/media/img/ParaibanaTech.png';
import Manaira from '../routes/media/img/Logo_Manaira.png';
import Fablab from '../routes/media/img/Logo FabLab-UFPB.png';
import Evlos from '../routes/media/img/evlos_logo.png';
import Iane from '../routes/media/img/logo_iane.png';

function Patrocinadores() {
  return (
    <div id='patrocinadores'>
      {/* Primeira linha */}
      <div className="grid-realizacao">
        <div className="realizacao">
          <h2>Realização:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.tresbedelas.com.br/">
              <img src={LogoTresbe} alt="Logo Tresbe" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/ilhatechoficial/">
              <img src={LogoIlha} alt="Logo Ilha" />
            </a>
          </div>
        </div>

        <div className="apoio">
          <h2>Apoio Institucional:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/tvcabobranco/">
              <img src={cabobranco} alt="TV Cabo Branco" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/tvparaiba/">
              <img src={tvpb} alt="TV Paraíba" />
            </a>
          </div>
        </div>
      </div>

      {/* Título Patrocinadores */}
      <div className='titulo_patrocinadores'>
        <div className='linha_patrocinio'></div><h1>Patrocinadores</h1><div className='linha_patrocinio'></div>
      </div>

      {/* Seção de Patrocinadores */}
      <div className="grid-patrocinadores">
        <div id='patrocinador_estrela' className="patrocinador">
          <h2 className='cota_patrocinio'>Marte:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/manairashopping/">
              <img id='logo_manaira' className='marte_logo' src={Manaira} alt="Manaíra Shopping" />
            </a>
          </div>
        </div>

        <div className="patrocinador">
          <h2 className='cota_patrocinio'>Lua:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/graficajb/">
              <img className='lua_logo' id='logo_menor' src={JB} alt="Gráfica JB" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/ianealimentos/">
              <img className='lua_logo' id='logo_menor' src={Iane} alt="Iane Alimentos" />
            </a>
          </div>
        </div>

        <div className="patrocinador">
          <h2 id='titulo_menor' className='cota_patrocinio'>Terra:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/fablabufpb/">
              <img className='terra_logo' id='logo_menor' src={Fablab} alt="FabLab UFPB" />
            </a>
          </div>
        </div>

        <div className="patrocinador">
          <h2 id='titulo_menor' className='cota_patrocinio'>Estrela:</h2>
          <div className='logos_patrocinio'>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/grafica.3jm/">
              <img className='estrela_logo' src={JM} alt="Gráfica 3JM" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/geaneamaraloficial/">
              <img className='estrela_logo' src={Geane} alt="Geane Amaral" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.instagram.com/paraibanatech/">
              <img className='estrela_logo' src={Paraibana} alt="Paraibana tech" />
            </a>
            <a className='logo_patrocinadores_page' href="https://www.evlos4u.com/">
              <img className='estrela_logo' src={Evlos} alt="Evlos" />
            </a>
          </div>
        </div>
      </div>
    
    <a className='btn_queropatrocinar' href="#"><h1>Quero ser um patrocinador</h1></a>
    
    </div>
  );
}

export default Patrocinadores;
